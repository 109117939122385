import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { AppConfigService } from '@core/app-config/app-config.service';
import { EventsService } from '@core/events';
import { WINDOW } from '@core/injection-tokens';
import { NewTrueBlueSection, TrueBlueSection } from '@shared/header';
import { HeaderService } from '@shared/header/header.service';

/**
 * @description Logged out user and dropdown in a mobile viewport
 * @WhereIsItUsed header mobile component
 */
@Component({
  selector: 'jb-header-mobile-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileSignInComponent {
  @Input() trueBlueSection: TrueBlueSection;
  @Input() newTrueBlueSection: NewTrueBlueSection;

  isModalOpen$ = this.headerService.isSignInModalOpen$;
  isNewTBSignedOut: boolean;

  constructor(
    @Inject(WINDOW) public window,
    private eventsService: EventsService,
    private headerService: HeaderService,
    private appConfigService: AppConfigService,
  ) {
    this.isNewTBSignedOut = this.appConfigService.newTBSignedOut;
  }

  signIn() {
    this.eventsService.dispatchCustomEvent(
      EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT,
    );
  }

  toggleSignInModal() {
    this.headerService.toggleSignInModal();
  }
}

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LanguageService } from '@core/language/language.service';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { FooterModule } from '@shared/footer/footer.module';
import { ApiOverridesInterceptor } from '@shared/interceptors/api-overrides/api-overrides.interceptor';
import { ClientSideTranslationInterceptor } from '@shared/interceptors/client-side-translation/client-side-translation.interceptor';
import { FetchInterceptor } from '@shared/interceptors/fetch/fetch.interceptor';
import { FullstoryErrorReporterInterceptor } from '@shared/interceptors/fullstory-error-reporter.interceptor';
import { IconCacheInterceptor } from '@shared/interceptors/icon-cache/icon-cache.interceptor';
import { LowerCaseUrlSerializer } from '@shared/interceptors/lowercase-url-serializer/url-serializer.interceptor';
import { UrlDictionaryVersioningInterceptor } from '@shared/interceptors/url-dictionary-versioning/url-dictionary-versioning.interceptor';
import {
  ADDITIONAL_SITEMAP_ROUTES,
  SITEMAP_DICTIONARY,
} from '@shared/ui/links/linkers/sitemap-dictionary.token';
import { LanguageRedirectResolver } from '@store/router/language-redirect.resolver';
import {
  IS_BROWSER as CL_IS_BROWSER,
  JbIconModule,
  JbSrOnlyModule,
} from 'jb-component-library';
import { CookieModule } from 'ngx-cookie';

import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '../environments/store-devtools';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  AppConfigService,
  DOCUMENT,
  ENV_CONFIG,
  GlobalErrorHandler,
  HOST,
  INITIAL_SITEMAP,
  IS_WEB_COMPONENT,
  LOCAL_STORAGE,
  SESSION_STORAGE,
  WINDOW,
} from './core';
import { WindowActionsModule } from './core/window-actions';
import { NotFoundPageModule } from './feature/not-found-page';
import {
  configFactory,
  documentFactory,
  hostFactory,
  initialSitemapFactory,
  localStorageFactory,
  oktaFactory,
  sessionStorageFactory,
  sitemapDictionaryFactory,
  windowFactory,
} from './global-factories';
import { icons } from './icon-manifest';
import { HeaderModule } from './shared';
import { SSRComponent } from './ssr.component';
import { ROOT_EFFECTS } from './store/effects';
import { initialState } from './store/initial-state';
import { getMetaReducers, reducers } from './store/reducers';
import { TranslateTestingModule } from './testing/translation.testing';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
    CookieModule.forRoot(),
    WindowActionsModule.forRoot(),
    StoreModule.forRoot(reducers, {
      initialState,
      // Preserve ngrx 8 behaviour
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([...ROOT_EFFECTS]),
    NotFoundPageModule,
    HeaderModule,
    FooterModule,
    TranslateTestingModule,
    AppRoutingModule,
    JbSrOnlyModule,
    JbIconModule.withIcons(icons),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Full,
    }),
    FetchInterceptor,
    ...(!!StoreDevtoolsModule ? [StoreDevtoolsModule] : []),
    OktaAuthModule,
  ],
  declarations: [AppComponent, SSRComponent],
  providers: [
    { provide: CL_IS_BROWSER, useValue: true },
    { provide: IS_WEB_COMPONENT, useValue: false },
    { provide: LOCAL_STORAGE, useFactory: localStorageFactory },
    { provide: SESSION_STORAGE, useFactory: sessionStorageFactory },
    { provide: ENV_CONFIG, useFactory: configFactory },
    { provide: WINDOW, useFactory: windowFactory },
    { provide: DOCUMENT, useFactory: documentFactory },
    { provide: HOST, useFactory: hostFactory },
    { provide: USER_PROVIDED_META_REDUCERS, useFactory: getMetaReducers },
    {
      provide: SITEMAP_DICTIONARY,
      useFactory: sitemapDictionaryFactory,
      deps: [AppConfigService, ADDITIONAL_SITEMAP_ROUTES],
    },
    {
      provide: ADDITIONAL_SITEMAP_ROUTES,
      useValue: {
        '/best-fare-finder': 'generic',
        '/checkin/check-in': 'generic',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FullstoryErrorReporterInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiOverridesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      // prefix url with i18n-next-static for text-content service requests
      useClass: ClientSideTranslationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlDictionaryVersioningInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IconCacheInterceptor,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    LanguageRedirectResolver,
    {
      provide: OKTA_CONFIG,
      useFactory: oktaFactory,
      deps: [AppConfigService, LanguageService],
    },
    /* duplicate
    LanguageRedirectResolver,
    */
    { provide: INITIAL_SITEMAP, useFactory: initialSitemapFactory },
  ],
  bootstrap: [SSRComponent],
})
export class AppModule {}

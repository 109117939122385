import { parseFooter } from '@core/cms/api-transform/cms-footer.api-transform';
import { parseHeader } from '@core/cms/api-transform/cms-header.api-transform';
import {
  FooterResponse,
  LanguageLink,
} from '@core/cms/types/cms-footer.response.type';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  HeaderInnerType,
  TravelAlerts,
} from '@shared/header/types/header.inner.type';
import { deepMerge } from '@shared/ui/utils/global-utils/deep-merge';

import { AppState } from '../shared/types/app-state.type';
import { GlobalComponentState, GlobalState, PageData } from './types';

export const global: MemoizedSelector<AppState, GlobalState> =
  createFeatureSelector('global');

export const globalComponent: MemoizedSelector<AppState, GlobalComponentState> =
  createSelector(global, state => state.componentConfig);

export const travelAlerts: MemoizedSelector<AppState, TravelAlerts> =
  createSelector(global, state => state.travelAlerts);

export const headerTranslations = createSelector(
  global,
  state => state.headerTranslations,
);
export const footerTranslations = createSelector(
  global,
  state => state.footerTranslations,
);

export const rawHeader = createSelector(global, state => state.rawHeader);
export const rawFooter = createSelector(global, state => state.rawFooter);

export const header: MemoizedSelector<AppState, HeaderInnerType> =
  createSelector(global, state => state.header);

export const translatedHeader = createSelector(
  rawHeader,
  headerTranslations,
  (rHeader, tHeader) => {
    return parseHeader(deepMerge(rHeader, tHeader));
  },
);

export const isCountdownClock: MemoizedSelector<AppState, boolean> =
  createSelector(
    global,
    state => state && !!state.header && !!state.header.countdownClock,
  );

export const footer: MemoizedSelector<AppState, FooterResponse> =
  createSelector(global, state => state.footer);

export const translatedFooter = createSelector(
  rawFooter,
  footerTranslations,
  (rFooter, tFooter) => {
    return parseFooter(deepMerge(rFooter, tFooter));
  },
);

export const footerLanguageLinks: MemoizedSelector<AppState, LanguageLink[]> =
  createSelector(footer, state =>
    state
      ? state.footnoteSection.utilityNavigation.links.map(link => ({
          ...link,
          lang: link.lang.toLowerCase() as LanguageLink['lang'],
        }))
      : [],
  );

export const template: MemoizedSelector<AppState, any> = createSelector(
  global,
  state => state.template,
);

export const lastRequestedEndpoint: MemoizedSelector<AppState, string> =
  createSelector(global, state => state.lastRequestedEndpoint);

export const isHeaderRendered: MemoizedSelector<AppState, boolean> =
  createSelector(global, state => state.isHeaderRendered);

export const primaryTemplateRendered: MemoizedSelector<AppState, boolean> =
  createSelector(global, state => state.primaryTemplateRendering);

export const secondaryTemplateRendered: MemoizedSelector<AppState, boolean[]> =
  createSelector(global, state => state.secondaryTemplateRendering);

export const tertiaryTemplateRendered: MemoizedSelector<AppState, boolean[]> =
  createSelector(global, state => state.tertiaryTemplateRendering);

export const templateRendered: MemoizedSelector<AppState, boolean> =
  createSelector(
    primaryTemplateRendered,
    secondaryTemplateRendered,
    tertiaryTemplateRendered,
    (primary, secondary, tertiary) =>
      primary && secondary.length === 0 && tertiary.length === 0,
  );

export const pageData: MemoizedSelector<AppState, PageData> = createSelector(
  global,
  state => {
    if (state && state.template && state.template.data) {
      return state.template.data;
    }
  },
);

export const isCmsFailed: MemoizedSelector<AppState, boolean> = createSelector(
  global,
  state => state?.isCmsFailed,
);

import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfigService } from '@core/app-config/app-config.service';
import { EventsService } from '@core/events/events.service';
import {
  CookieStorageService,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  WINDOW,
} from '@core/index';
import { generateRoutes } from '@store/router/router.utils';
import { JbI18nUtilsService, JbLocaleCodes } from 'jb-component-library';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  withLatestFrom,
} from 'rxjs';

import { routes } from '../../app.routes';
import { detectLanguage } from './utils/detect-language';
import { getNewLanguagePath } from './utils/get-new-language-path';

const LANG_MAPPINGS = {
  en: {
    locale: 'en-US',
    clLocale: JbLocaleCodes['en-US'],
    letters: 'ENG',
  },
  es: {
    locale: 'es-ES',
    clLocale: JbLocaleCodes['es'],
    letters: 'ESP',
  },
  fr: {
    locale: 'fr-FR',
    clLocale: JbLocaleCodes['fr'],
    letters: 'FRA',
  },
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private isHola: boolean;
  public readonly supportedLanguages: string[];
  public readonly holaLanguagesURLs: any;
  public readonly privateLanguage$: BehaviorSubject<string>;
  public readonly language$: Observable<string>;

  private window: Window;
  private appConfig: AppConfigService;
  private cookieService: CookieStorageService;
  private eventsService: EventsService;
  private isWebComponent: InjectionTokenIsWebComponent;
  private router: Router;
  private jbI18nUtilsService: JbI18nUtilsService;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      // for now on ssr we only focus on English
      this.appConfig = inject(AppConfigService);
      this.jbI18nUtilsService = inject(JbI18nUtilsService);
      this.isWebComponent = inject(IS_WEB_COMPONENT);
      this.isHola = false;
      this.supportedLanguages = ['en'];
      this.privateLanguage$ = new BehaviorSubject<string>(null);
      this.language$ = this.privateLanguage$
        .asObservable()
        .pipe(filter(Boolean), distinctUntilChanged());
      this.setLanguage('en');
      return;
    }
    this.appConfig = inject(AppConfigService);
    this.jbI18nUtilsService = inject(JbI18nUtilsService);
    this.isWebComponent = inject(IS_WEB_COMPONENT);
    this.window = inject(WINDOW);
    this.cookieService = inject(CookieStorageService);
    this.eventsService = inject(EventsService);
    this.router = inject(Router);
    // to-do move this to detect language and init isHola based on detected language
    this.isHola = this.window.location.hostname.includes('hola');
    this.supportedLanguages = [
      // to-do remove this line all supported language should come from i18nSupportedLanguages
      'en',
      ...this.appConfig.i18nSupportedLanguages.filter(lang => lang !== 'en'),
    ];
    this.holaLanguagesURLs = this.appConfig.supportedLanguages;
    this.privateLanguage$ = new BehaviorSubject<string>(null);
    this.language$ = this.privateLanguage$ // observes when language is changed
      .asObservable()
      .pipe(filter(Boolean), distinctUntilChanged());

    this.init();
  }

  init() {
    // at this point i have not done any router navigation
    // if language is english cookie should be undefined
    this.setLanguage(
      // detects lang from cookie or url
      detectLanguage(
        this.appConfig.languageInUrlEnabled,
        this.window.location.pathname,
        this.supportedLanguages,
        this.window,
        this.cookieService.getCookie('lang'),
      ),
      true,
    );

    // Update lang through navigation
    // ex.: without reloading the app we navigate to /fr/page
    if (
      !this.isWebComponent &&
      !this.isHola &&
      this.appConfig.languageInUrlEnabled
    ) {
      // this fires only after router is configure and fires first navigation
      this.router.events
        .pipe(
          // observes router navivation to check for language in url
          filter(event => event instanceof NavigationEnd),
          map((event: NavigationEnd) => event.url),
          distinctUntilChanged(),
          map(() => {
            // detects lang from cookie or url
            const detected = detectLanguage(
              this.appConfig.languageInUrlEnabled,
              this.window.location.pathname,
              this.supportedLanguages,
              this.window,
              this.cookieService.getCookie('lang'),
            );
            console.log(
              'LanguageService => init => detected language',
              detected,
            );
            return detected;
          }),
          // language$ observers when we select a language
          // we can only select a lang from this service constructor,
          // via a cookie or url, or when we call
          // switchLanguage
          withLatestFrom(this.language$),
          filter((arg: any) => {
            const [detected, current] = arg;
            // check if detected language from cookie or url is diff
            // than last selected/current language
            return detected !== current;
          }),
        )
        .subscribe(([lang, _]) => {
          // switch to detected language
          this.switchLanguage(lang);
        });
    }
  }

  public getLanguage() {
    return this.privateLanguage$.getValue();
  }

  public languageToJbLocaleCodes(language: string): JbLocaleCodes {
    return LANG_MAPPINGS[language]?.clLocale ?? JbLocaleCodes['en-US'];
  }

  public getLocale(): string {
    // used in analytics service. Can they be happy with 2 letter?
    return LANG_MAPPINGS[this.getLanguage()]?.locale;
  }

  public getLanguage3Letter(): string {
    // used in analytics service. Can they be happy with 2 letter?
    return LANG_MAPPINGS[this.getLanguage()]?.letters;
  }

  // this is only invoked from the footer or the router observer on languageService init
  // in both cases language is a supported language
  public switchLanguage(language: string) {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      if (this.getLanguage() !== language) {
        this.setLanguage(language);
      }
      return;
    }
    // to-do remove this at this point language is a supported language
    if (!this.supportedLanguages.includes(language)) {
      return;
    }

    // todo: Remove Logic when MP is off
    // from hola or to hola
    // to-do remove isHola otherwise if isHola is true will prevent user from swithcing out from spanish
    if (!this.appConfig.disableHola && (language === 'es' || this.isHola)) {
      const currentUrl = new URL(this.window.location.href);
      const baseURL = this.holaLanguagesURLs[language].replace(/\/$/, '');
      // when navigating to MP we don't want to keep the language in the URL
      const firstUrlSegment = currentUrl.pathname.split('/')[1];
      const pathname = this.supportedLanguages.includes(firstUrlSegment)
        ? currentUrl.pathname.replace(`/${firstUrlSegment}`, '')
        : currentUrl.pathname;

      // to-do replace with setLanguage - cookie should be set only from setLanguage
      this.cookieService.setCookie('lang', language);
      this.window.location.href = `${currentUrl.protocol}//${baseURL}${pathname}${currentUrl.search}${currentUrl.hash}`;
      return;
    }

    if (this.getLanguage() === language) {
      return;
    }

    this.setLanguage(language);
  }

  // set language is only invoked from this service constructor or in switchLanguage
  // isInitialLoad is true only when called from constructor
  // language is the detected language from the cookie or url or provide by the footer control
  // in all those cases language is a supported language
  private setLanguage(language: string, isInitialLoad = false) {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      console.log('ssr: setLanguage', language);
      this.jbI18nUtilsService.setLocale(this.languageToJbLocaleCodes(language));
      this.privateLanguage$.next(language);
      return;
    }
    // to-do remove this at this point language is a supported language
    if (!this.supportedLanguages.includes(language)) {
      return;
    }
    // in hola there is only one language
    // to-do this will prevent user from switching out from spanish
    language = this.isHola ? 'es' : language;

    this.jbI18nUtilsService.setLocale(this.languageToJbLocaleCodes(language));

    if (this.isWebComponent && !isInitialLoad) {
      this.eventsService.dispatchCustomEvent(
        EventsService.CUSTOM_EVENTS.JB_LANGUAGE_CHANGE_OUTPUT_EVENT,
        { language: language },
      );
      return;
    }

    this.cookieService.setCookie('lang', language);
    this.window.document.documentElement.setAttribute('lang', language);
    if (!isInitialLoad && !this.isHola) {
      // this is only executed when setLanguage is called from
      // switchLanguage
      // to-do move ot this from set language this should be invoked directly from
      // switchLanguage after setLanguage is invoked.
      this.updateAndNavigateLanguagePath(language);
    }
    this.privateLanguage$.next(language);
  }

  /**
   * Updates the routes and navigates to a new language path if necessary.
   *
   * @param language - The language to update the routes and navigate to.
   * @notes This can be moved out of the service, say into (maybe) app-routing.module.ts
   *       listing for the language change event and updating the routes and navigating
   *       but it is here for now to keep the language switch logic in one place.
   */
  // this is invoked when we switch to a new Language
  private updateAndNavigateLanguagePath(language: string) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      console.log('ssr: updateAndNavigateLanguagePath cancelled');
      return;
    }
    if (
      this.appConfig.parsedSitemap && // AppRoutingModule has to parse the sitemap first
      this.appConfig.languageInUrlEnabled &&
      // to-do check why to invoke useLanguageInUrl if language is not english
      // useLanguageInUrl should always be true so checking language !== en is enough
      (this.appConfig.useLanguageInUrl(language) || language === 'en')
    ) {
      // updates the routes with language param support in non-english cases
      // after we switch to new language it reconfigures the router
      // injects language guard and resolver to validate new language in urls
      // to-do remove generateRoutes from here
      // router configuration should only be reset from app-routing.module
      const generatedRoutes = generateRoutes(
        this.appConfig.parsedSitemap,
        routes,
        this.appConfig,
        language,
      );
      this.router.resetConfig(generatedRoutes);
      const newPath = getNewLanguagePath(
        this.router.parseUrl(this.router.url),
        this.supportedLanguages,
        language,
        true,
      );
      this.router.navigate(newPath);
    }
  }
}

<button
  tabindex="0"
  *ngIf="
    trueBlueSection.template === 'TRUEBLUE' &&
    window.location.href !== 'about:blank'
  "
  (click)="toggleSignInModal()"
  class="sign-in-button klarheit f6 fw6 white no-underline nowrap dib pointer pa0 relative"
>
  <span
    class="flex flex-column justify-start items-center w2 h2"
    *ngFor="let link of trueBlueSection.link"
  >
    <img
      [src]="link.image[0]?.src || ''"
      [alt]="link.image[0]?.alt"
      height="32"
      width="32"
    />
  </span>
  <hr
    [ngClass]="(isModalOpen$ | async) ? 'w-100' : 'w-0'"
    class="db absolute bottom-0 hr-underline"
  />
</button>

<div *ngIf="(isModalOpen$ | async) && isNewTBSignedOut">
  <dot-tb-signed-out
    class="sign-in-modal bg-white"
    [content]="newTrueBlueSection.loggedOutSection"
  ></dot-tb-signed-out>
</div>
<div
  *ngIf="(isModalOpen$ | async) && !isNewTBSignedOut"
  class="sign-in-modal bg-white ph3 pt5"
>
  <!-- top section -->
  <h3 class="mb3" *ngIf="trueBlueSection.loggedOutSection.trueBlueText">
    {{ trueBlueSection.loggedOutSection.trueBlueText }}
  </h3>
  <p
    class="klarheit s-body charcoal mb3 tl"
    *ngIf="trueBlueSection.loggedOutSection.trueBlueSubText"
  >
    {{ trueBlueSection.loggedOutSection.trueBlueSubText }}
  </p>
  <div class="flex flex-column tl klarheit">
    <ng-container
      *ngIf="trueBlueSection.loggedOutSection.topSection?.children.length > 0"
    >
      <a
        jbStandaloneLink
        *ngFor="
          let item of trueBlueSection.loggedOutSection.topSection.children;
          let last = last
        "
        [jbSitemapLinker]="item.href"
        [target]="item.newWindow"
        class="s-body"
        [ngClass]="{ pb2: !last }"
        (click)="toggleSignInModal()"
      >
        {{ item.title }}
      </a>
    </ng-container>
  </div>
  <!-- control section -->
  <div class="flex flex-column items-center klarheit mt9 mb3">
    <ng-container
      *ngFor="
        let control of trueBlueSection.loggedOutSection.ctaSection?.children
      "
    >
      <ng-container *ngIf="control?.actionType === 'BUTTON'; else actionLink"
        ><a
          *ngIf="control?.linkType !== 'SIGNIN_LINK'"
          jbButton
          class="mt4"
          [jbSitemapLinker]="control.href"
          [target]="control.newWindow"
        >
          {{ control.title }}
        </a>
        <a
          *ngIf="control?.linkType === 'SIGNIN_LINK'"
          jbButton
          class="mt4"
          href="javascript:void(0)"
          (click)="signIn()"
          [target]="control.newWindow"
        >
          {{ control.title }}
        </a>
      </ng-container>
      <ng-template #actionLink>
        <a
          jbActionLink
          *ngIf="control?.linkType !== 'SIGNIN_LINK'"
          class="mt4"
          [jbSitemapLinker]="control.href"
          [target]="control.newWindow"
        >
          <span>{{ control.title }}</span>
          <jb-icon class="pl1" name="rightFilled"></jb-icon>
        </a>
        <a
          jbActionLink
          *ngIf="control?.linkType === 'SIGNIN_LINK'"
          class="mt4"
          href="javascript:void(0)"
          (click)="signIn()"
          [target]="control.newWindow"
        >
          <span>{{ control.title }}</span>
          <jb-icon class="pl1" name="rightFilled"></jb-icon>
        </a>
      </ng-template>
    </ng-container>
  </div>
</div>

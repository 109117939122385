import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AnalyticsService,
  CookieStorageService,
  JBABTestService,
  WindowService,
} from '@core/index';
import { DOCUMENT, LOCAL_STORAGE, WINDOW } from '@core/injection-tokens';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { waitForFullStory } from '@shared/ui/utils/global-utils/full-story';
import { PreferenceCenterFacade } from '@store/preference-center/preference-center.facade';
import { pathOr } from 'ramda';
import { combineLatest, from, of as observableOf, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AuthActions, UserLoggedInOktaFlow } from '../auth/auth.actions';
import { AuthFacade } from '../auth/auth.facade';
import {
  AllContentLoadFinish,
  AnalyticsActions,
  BffAnalyticsEventCall,
  BookerLoadFinished,
  BookerLoadStart,
  ContentLoadStart,
  ForgotPasswordDetails,
  ForgotPasswordServiceErrorTracking,
  HeaderLoadFinished,
  HeaderStart,
  LogProfileInfoEventCall,
  PriorityContentLoadFinish,
  TbLoginDetails,
  TrackSubmitEventCall,
} from './analytics.actions';
import { getTotalPassengers } from './analytics.logic';
import {
  fullstoryIdentify,
  sendBookerLoadFSEvent,
  sendContentLoadFSEvent,
  sendHeaderLoadFSEvent,
} from './utils';

@Injectable()
export class AnalyticsEffects {
  fullstoryIdentify: Function;

  constructor(
    private actions$: Actions,
    @Inject(DOCUMENT) private document,
    @Inject(LOCAL_STORAGE) private localStorage,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private windowService: WindowService,
    private abTestService: JBABTestService,
    private authFacade: AuthFacade,
    private preferenceCenterFacade: PreferenceCenterFacade,
    private cookieStorage: CookieStorageService,
    @Inject(WINDOW) private window,
  ) {
    this.fullstoryIdentify = fullstoryIdentify;
  }

  trackSubmitCalled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TrackSubmitEventCall>(AnalyticsActions.TRACK_SUBMIT_CALLED),
        mergeMap(() =>
          combineLatest([
            this.authFacade.authProfile.pipe(first()),
            observableOf(this.route.snapshot.queryParams['intcmp']),
          ]),
        ),
        withLatestFrom(this.abTestService.omnitureABTestExperienceValue),
        tap(([[authProfile, intcmp], omnitureABTestExperienceValue]) => {
          let tbId;
          let tbPoints;
          const pathname = this.document.location.pathname;
          const checkClickTracking =
            !!this.localStorage &&
            typeof this.localStorage.clickTracking !== undefined &&
            this.localStorage.clickTracking !== '';
          const clickTrackingVal = this.localStorage.clickTracking;
          const intcmpVal = this.route.snapshot.queryParams['intcmp']
            ? this.route.snapshot.queryParams['intcmp']
            : '';
          if (authProfile) {
            tbId = authProfile.membershipid;
            tbPoints = authProfile.points;
          }
          if (pathname === '/') {
            this.analyticsService.trackPageView({
              events: 'event40',
              tbNumber: tbId,
              tbPoints,
              prop37: clickTrackingVal,
              eVar1: intcmpVal,
              eVar70: omnitureABTestExperienceValue,
            });
          } else if (checkClickTracking) {
            this.analyticsService.trackPageView({
              prop37: clickTrackingVal,
              eVar1: intcmpVal,
              eVar70: omnitureABTestExperienceValue,
            });
            this.clearClickTrackingVal();
          } else {
            this.analyticsService.trackPageView({
              eVar1: intcmpVal,
              eVar70: omnitureABTestExperienceValue,
            });
          }
        }),
      ),
    { dispatch: false },
  );

  tbLoginDetailsTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TbLoginDetails>(AnalyticsActions.TB_LOGIN_DETAILS),
        tap(({ payload }) => {
          this.analyticsService.trackEvent('TB Login Validation Page Errors', {
            pageValidationError: payload,
          });
        }),
      ),
    { dispatch: false },
  );

  tbUserLoginDetailsTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoggedInOktaFlow>(AuthActions.OKTA_USER_LOGGED_IN),
        tap(loginPayload => {
          if (!loginPayload['error']) {
            this.analyticsService.trackEvent('True Blue Status', {
              tbStatus: `${this.analyticsService.mosaicStatus} | ${this.analyticsService.cardStatus}`,
            });
          }
          const message = pathOr('', ['payload', 'message'], loginPayload);
          if (loginPayload['error']) {
            if (message) {
              this.analyticsService.trackEvent('TB Login Server Errors', {
                eVar40: message,
              });
            }
          }
        }),
      ),
    { dispatch: false },
  );

  forgotPasswordDetailsTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ForgotPasswordDetails>(AnalyticsActions.FORGOT_PASSWORD_DETAILS),
        tap(({ payload }) => {
          this.analyticsService.trackEvent(
            'Forgot Password Validation Page Errors',
            {
              pageValidationError: payload,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  forgotPasswordDetailsServiceErrorTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ForgotPasswordServiceErrorTracking>(
          AnalyticsActions.FORGOT_PASSWORD_SERVICE_ERROR,
        ),
        tap(({ payload }) => {
          this.analyticsService.trackEvent('Forgot Password Server Errors', {
            eVar40: payload,
          });
        }),
      ),
    { dispatch: false },
  );

  bffAnalyticsEventTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BffAnalyticsEventCall>(
          AnalyticsActions.BFF_ANALYTICS_EVENT_CALL,
        ),
        map(bffFormControl => bffFormControl.payload),
        tap(payload => {
          const travelersValue = pathOr(
            '',
            ['travelers', 'allTravelers'],
            payload,
          );
          let totalPassengers: number;
          if (travelersValue) {
            totalPassengers = getTotalPassengers(travelersValue);
          }
          const createAnalyticsVariables = {
            tripType: pathOr('', ['tripType', 'value'], payload),
            // orginToDestination: JFKBOS
            originToDestination:
              pathOr('', ['cityPair', 'originCode'], payload) +
              pathOr('', ['cityPair', 'destinationCode'], payload),
            adults: pathOr(0, ['travelers', 'allTravelers', 'adults'], payload),
            children: pathOr(
              0,
              ['travelers', 'allTravelers', 'children'],
              payload,
            ),
            infants: pathOr(
              0,
              ['travelers', 'allTravelers', 'infants'],
              payload,
            ),
            fareType: pathOr('', ['bookWithPoints', 'value'], payload)
              ? 'Points'
              : 'Cash',
            totalPassengers,
            events: 'event70',
          };

          this.analyticsService.trackEvent(
            'Best Fare Finder Calendar',
            createAnalyticsVariables,
          );
        }),
      ),
    { dispatch: false },
  );

  headerStartLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<HeaderStart>(AnalyticsActions.HEADER_INIT_EVENT_CALL),
        tap(() => {
          this.window.performance.mark(AnalyticsActions.HEADER_INIT_EVENT_CALL);
        }),
        map(() => ({
          startTime: this.window.performance.getEntriesByName(
            AnalyticsActions.HEADER_INIT_EVENT_CALL,
          )[0].startTime,
        })),
        switchMap(startData =>
          this.actions$.pipe(
            ofType<HeaderLoadFinished>(
              AnalyticsActions.HEADER_FINISHED_INIT_EVENT_CALL,
            ),
            tap(() => {
              this.window.performance.mark(
                AnalyticsActions.HEADER_FINISHED_INIT_EVENT_CALL,
              );
            }),
            withLatestFrom(this.windowService.isMobile),
            map(isMobile => ({
              ...startData,
              endTime: this.window.performance.getEntriesByName(
                AnalyticsActions.HEADER_FINISHED_INIT_EVENT_CALL,
              )[0].startTime,
              isMobile,
            })),
          ),
        ),
        tap(({ startTime, endTime, isMobile }) => {
          const duration = endTime - startTime;
          const headerType = isMobile[1] ? 'mobile' : 'desktop';

          sendHeaderLoadFSEvent(this.window, {
            startTime,
            endTime,
            duration,
            headerType,
          });
        }),
      ),
    { dispatch: false },
  );

  bookerStartedLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BookerLoadStart>(AnalyticsActions.BOOKER_LOAD_START),
        tap(({ payload }) => {
          this.window.performance.mark(
            AnalyticsActions.BOOKER_LOAD_START + payload,
          );
        }),
        map(({ payload }) => ({
          eventId: payload,
          startTime: this.window.performance.getEntriesByName(
            AnalyticsActions.BOOKER_LOAD_START + payload,
          )[0].startTime,
        })),
        switchMap(startData =>
          this.actions$.pipe(
            ofType<BookerLoadFinished>(AnalyticsActions.BOOKER_LOAD_DONE),
            tap(({ payload }) => {
              this.window.performance.mark(
                AnalyticsActions.BOOKER_LOAD_DONE + payload,
              );
            }),
            map(({ payload }) => ({
              // Subtract 1000 for debounceTime
              ...startData,
              eventId: payload,
              endTime:
                this.window.performance.getEntriesByName(
                  AnalyticsActions.BOOKER_LOAD_DONE + payload,
                )[0].startTime - 1000,
            })),
          ),
        ),
        tap(({ eventId, startTime, endTime }) => {
          const duration = endTime - startTime;
          sendBookerLoadFSEvent(this.window, {
            eventId,
            startTime,
            endTime,
            duration,
          });
        }),
      ),
    { dispatch: false },
  );

  contentStartedLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ContentLoadStart>(AnalyticsActions.CONTENT_LOAD_START),
        tap(() => {
          this.window.performance.mark(AnalyticsActions.CONTENT_LOAD_START);
        }),
        map(() => ({
          startTime: this.window.performance.getEntriesByName(
            AnalyticsActions.CONTENT_LOAD_START,
          )[0].startTime,
        })),
        switchMap(startData =>
          this.actions$.pipe(
            ofType<PriorityContentLoadFinish>(
              AnalyticsActions.PRIORITY_CONTENT_LOAD_DONE,
            ),
            tap(() => {
              this.window.performance.mark(
                AnalyticsActions.PRIORITY_CONTENT_LOAD_DONE,
              );
            }),
            map(() => ({
              ...startData,
              priorityEndTime: this.window.performance.getEntriesByName(
                AnalyticsActions.PRIORITY_CONTENT_LOAD_DONE,
              )[0].startTime,
            })),
          ),
        ),
        switchMap(data =>
          this.actions$.pipe(
            ofType<AllContentLoadFinish>(
              AnalyticsActions.ALL_CONTENT_LOAD_DONE,
            ),
            tap(() => {
              this.window.performance.mark(
                AnalyticsActions.ALL_CONTENT_LOAD_DONE,
              );
            }),
            map(() => ({
              ...data,
              allContentEndTime: this.window.performance.getEntriesByName(
                AnalyticsActions.ALL_CONTENT_LOAD_DONE,
              )[0].startTime,
            })),
          ),
        ),
        tap(({ startTime, priorityEndTime, allContentEndTime }) => {
          const duration = allContentEndTime - startTime;
          sendContentLoadFSEvent(this.window, {
            startTime,
            priorityEndTime,
            allContentEndTime,
            duration,
          });
          this.window.performance.clearMarks();
          this.window.performance.clearResourceTimings();
        }),
      ),
    { dispatch: false },
  );

  logProfileInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LogProfileInfoEventCall>(AnalyticsActions.LOG_PROFILE_INFO),
        switchMap(action => {
          const isUserLoggedIn = action.payload;

          return from(waitForFullStory(this.window)).pipe(
            catchError(() => {
              console.warn(
                "FullStory failed to load, user's identification failed",
              );
              return of(null);
            }),
            map(() => isUserLoggedIn),
          );
        }),
        switchMap(isUserLoggedIn => {
          if (isUserLoggedIn) {
            return combineLatest([
              this.authFacade.authProfile,
              this.preferenceCenterFacade.travelBankBalance.pipe(
                filter(tbBalance => tbBalance != null),
              ),
            ]).pipe(
              map(([authProfile, tbBalance]) => ({
                isUserLoggedIn,
                authProfile,
                tbBalance,
              })),
            );
          } else {
            return of({ isUserLoggedIn, authProfile: null, tbBalance: null });
          }
        }),
        tap(({ isUserLoggedIn, tbBalance, authProfile }) => {
          if (
            isUserLoggedIn &&
            this.cookieStorage.getCookie('jbFSIdentify') !== 'DONE'
          ) {
            this.cookieStorage.setCookie('jbFSIdentify', 'DONE');
            fullstoryIdentify(authProfile, tbBalance, this.window);
          } else if (
            !isUserLoggedIn &&
            this.cookieStorage.getCookie('jbFSIdentify') !== 'GUEST'
          ) {
            this.cookieStorage.setCookie('jbFSIdentify', 'GUEST');
            fullstoryIdentify(null, null, this.window);
          }
        }),
      ),
    { dispatch: false },
  );

  private clearClickTrackingVal() {
    this.localStorage.clickTracking = '';
  }
}

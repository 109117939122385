import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppConfigService } from '@core/app-config/app-config.service';
import { CookieStorageService } from '@core/storage';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// to-do reconfigure this class to become a route guard
// resolver is to resolve route data not for redirection
export class LanguageRedirectResolver implements Resolve<boolean> {
  constructor(
    private cookieService: CookieStorageService,
    private appConfig: AppConfigService,
    private router: Router,
  ) {}

  // this is executed only if the user selected a lang other than english first
  // then if the url has an empty path i.e. path: '' or '/'
  // we redirect the navigation to the current language root route
  // example '/es' or '/fr'
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    // Read the 'lang' value from the cookie
    // user will be redirected to this language root page
    // to-do use standard languageService.getLanguage()
    const lang = this.cookieService.getCookie('lang');
    const supportedLanguages = this.appConfig.supportedLanguages;

    // Define the route to redirect based on the 'lang' value else default to EN
    let redirectRoute = '/en';
    // to-do remove if condition if the lang is in the cookie was already validated by the
    // language service
    if (lang in supportedLanguages) {
      redirectRoute = '/' + lang;
    }

    // for english we don't need to redirect, todo: consider feature flagging this
    // to-do remove condition if language is english then generateRoutes
    // will remove resolver class from router configuration
    if (redirectRoute === '/en') {
      return of(true);
    }

    // Redirect to the appropriate route
    this.router.navigate([redirectRoute], { replaceUrl: true });
    return EMPTY;
  }
}

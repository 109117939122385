import { Route, Routes } from '@angular/router';
import { AppConfigService } from '@core/app-config';
import { LanguageGuard } from '@store/router/language.guard';
import { LanguageRedirectResolver } from '@store/router/language-redirect.resolver';
import { lensPath, over, reject } from 'ramda';

// this is invoked when we init the router or when we switchLanguage from the footer
// to-do: the cmsRoutes path already come prefixed with a language
// generateRoutes should only run on router init and replace the path ':lang' with specific path
// for each lang like path:'es' and its children would be the routes from cms prefixed with that lang
export const generateRoutes = (
  cmsRoutes: Routes,
  baseRoutes: Routes, // hard coded routes
  appConfig: AppConfigService,
  language: string = 'en', // current selected language
  devRoutes: Routes = [], // Added this as an argument since it was in your original code
  forceEn: boolean = false,
): Routes => {
  let combinedRoutes: Routes = [
    ...addChildRoutes(
      determineAppConfigPanelVisibility(baseRoutes, appConfig),
      cmsRoutes,
    ),
    {
      path: '**',
      loadChildren: () =>
        import('@feature/not-found-page/not-found-page.module').then(
          m => m.NotFoundPageModule,
        ),
    },
  ];

  if (!appConfig.isProdEnv && devRoutes.length > 0) {
    combinedRoutes = [...devRoutes, ...combinedRoutes];
  }

  // removes any routes that we explicitly do not want to have a language prefix
  // matches partial ignore route values as well (eg, /checkin matches /checkin/check-in
  // to-do: this should be executed only if language is not english
  const languageUrlRoutesWithoutIgnoredRoutes = combinedRoutes.map(route => {
    if (
      route.children &&
      route.path === '' &&
      !!appConfig.ignoreRoutesForLanguageInUrl.length
    ) {
      return {
        ...route,
        children: route.children.filter(
          childRoute =>
            !appConfig.ignoreRoutesForLanguageInUrl.some(ignoreRoute =>
              childRoute.path.includes(ignoreRoute),
            ),
        ),
      };
    }
    return route;
  });

  // to-do: this should be executed only if language is not english
  const routesThatWereIgnored =
    (!!appConfig.ignoreRoutesForLanguageInUrl.length &&
      combinedRoutes
        .find(route => route.path === '')
        ?.children?.filter(route =>
          appConfig.ignoreRoutesForLanguageInUrl.some(ignoreRoute =>
            route.path.includes(ignoreRoute),
          ),
        )) ||
    [];

  return appConfig.useLanguageInUrl(language) &&
    language &&
    (language !== 'en' || forceEn)
    ? [
        // if selected lang is not english return this
        {
          path: '',
          pathMatch: 'full',
          children: [],
          resolve: {
            LanguageRedirectResolver,
          },
        },
        ...routesThatWereIgnored,
        {
          // to-do: remove ':lang' instead use language var
          path: ':lang',
          // this guard check if route has a supported language
          // otherwiser prepend rout with a supported language b4 activate it
          canActivate: [LanguageGuard],

          children: languageUrlRoutesWithoutIgnoredRoutes,
        },
      ]
    : combinedRoutes; // if selected language is english return this
};

// Helper function for determining panel visibility, now a pure function
export const determineAppConfigPanelVisibility = (
  baseRoutes: Routes, // hard coded routes
  appConfig: AppConfigService,
): Routes => {
  if (appConfig.isProdEnv) {
    return over(
      lensPath([0, 'children']),
      current =>
        reject((route: Route) => route.path === 'appconfig-panel', current),
      baseRoutes,
    );
  }
  return baseRoutes;
};

// Your existing helper function for adding child routes, now a pure function
export const addChildRoutes = (
  previousRoutes: Routes,
  newRoutes: Routes,
): Routes => {
  return newRoutes.length > 0
    ? [
        {
          ...previousRoutes[0],
          children: [...newRoutes, ...previousRoutes[0].children],
        },
      ]
    : previousRoutes;
};

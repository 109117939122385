<nav
  #megaNavLinks
  (keydown.esc)="handleEsc()"
  (keydown)="handleKeydown($event)"
  role="navigation"
>
  <!-- subnav sections -->
  <ul
    class="flex-ns flex-wrap fl w-85-m dn db-ns ma0 pa0-ns pb5 pl0 ml3 mega-link"
  >
    <li
      *ngFor="let section of subNavSections; let i = index"
      class="mega-link-item pa3 pa0-ns ma0 fn list s-heading fl-ns"
    >
      <ng-container *ngIf="section?.section?.length || !!section.section">
        <!-- section title -->
        <a
          role="button"
          [attr.aria-expanded]="open[i]"
          (click)="toggleOpen($event, i)"
          href="javascript:void(0);"
          class="items-center main-nav-link flex justify-between white no-underline nowrap"
        >
          <span class="pr2-ns">{{ section.link.title }}</span>
          <jb-expandable-indicator theme="dark" [value]="open[i]">
          </jb-expandable-indicator>
        </a>
      </ng-container>
      <!-- section title that's a link -->
      <ng-container
        *ngIf="!!section && !section?.section?.length && !section?.section"
      >
        <a
          [jbSitemapLinker]="!!section && section.link && section?.link?.href"
          class="main-nav-link flex justify-between white no-underline nowrap"
        >
          {{ section.link.title }} {{ section?.section?.length }}
        </a>
      </ng-container>
      <!-- section drawer -->
      <jb-subnav-wrapper
        *ngIf="section?.section?.length || !!section.section"
        #subnavWrappers
        subnavWrapperId="{{ subnavWrapperId }}-{{ i }}"
        class="subnav-wrapper overflow-hidden absolute-ns left-0-ns right-0-ns bg-off-white core-blue w-100 menu-shadow klarheit zn-1 db"
        [subNavSection]="section"
        [isLoggedIn]="isLoggedIn"
        [ngClass]="{ dn: initialHide }"
        [animationClass]="
          open[i]
            ? somethingIsOpen
              ? 'fadein'
              : 'down'
            : somethingIsOpen
            ? 'fadeout'
            : 'up'
        "
        [isMobile]="isMobile"
      ></jb-subnav-wrapper>
      <hr [ngClass]="open[i] ? 'w-100' : 'w-0'" class="db-ns dn hr-underline" />
    </li>
  </ul>
  <!-- end subnav sections -->

  <!-- not logged in section -->
  <div
    *ngIf="!(isFetchingProfile | async) && !isLoggedIn && !hideTb"
    class="fr login dn db-ns relative flex items-center"
    [ngClass]="{ 'db-ns': !isLoggedIn, 'o-0': hideAtFirst }"
  >
    <span class="fl relative">
      <a
        tabindex="0"
        *ngIf="
          trueBlueSection.template === 'TRUEBLUE' &&
          window.location.href !== 'about:blank'
        "
        href="javascript:void(0)"
        class="main-nav-link klarheit f6 fw6 white no-underline nowrap dib pointer"
        jbAnchor
        #anchorSignedOut="jbAnchor"
        [flyout]="flyoutSignedOut"
        (click)="anchorSignedOut.toggle(); toggleOpen($event, menuLength)"
      >
        <div
          class="flex justify-start items-center menu-item-height"
          *ngFor="let link of trueBlueSection.link"
        >
          <div
            class="flex w2 h2 mr0 mr2-l"
            *ngIf="link.image[0]?.src"
            data-qaid="trueBlueImage"
          >
            <jb-image [src]="link.image[0].src"> </jb-image>
          </div>
          <span class="dn db-l" [attr.data-qaid]="link.title">{{
            link.title
          }}</span>
        </div>
      </a>
      <hr
        [ngClass]="(isTBSignedOutOpen | async) ? 'w-100' : 'w-0'"
        class="absolute bottom-0 db-ns dn hr-underline"
      />
    </span>
    <span class="mh2"></span>

    <!-- cart section for NOT logged in-->
    <ng-container>
      <!-- cart empty for NOT logged in-->
      <a
        *ngIf="!!(cartFacade.isCartEmpty | async) && shoppingCart"
        class="white pv3 relative-ns w-2"
        [jbSitemapLinker]="shoppingCart.emptyCart[0].link[0].href"
        [attr.target]="shoppingCart.emptyCart[0].link[0].newWindow"
        [attr.aria-label]="shoppingCart.emptyCart[0].alt"
      >
        <jb-image
          [src]="shoppingCart.emptyCart[0].src"
          class="fl pointer fill-white shopping-cart flex justify-center"
        ></jb-image>
      </a>
      <!-- end cart empty for NOT logged in-->
      <!-- cart not empty for NOT logged in-->
      <a
        *ngIf="!(cartFacade.isCartEmpty | async) && shoppingCart"
        class="white pv3 relative-ns w-2"
        [jbSitemapLinker]="shoppingCart.fullCart[0].link[0].href"
        [attr.target]="shoppingCart.fullCart[0].link[0].newWindow"
        [attr.aria-label]="shoppingCart.fullCart[0].alt"
      >
        <jb-image
          [src]="shoppingCart.fullCart[0].src"
          class="fl pointer fill-white shopping-cart flex justify-center"
        ></jb-image>
      </a>
      <!-- end cart not empty for NOT logged in-->
    </ng-container>
    <!-- end cart section for NOT logged in-->

    <!-- search section for NOT logged in -->
    <ng-container *ngIf="searchNavSection">
      <span class="mh2"></span>
      <a
        role="button"
        class="search-nav-link white flex justify-center pv3 w-2 relative"
        href="javascript:void(0);"
        [jbSitemapLinker]="
          searchNavSection.link[0] && searchNavSection.link[0].href
        "
        [target]="searchNavSection.link[0].newWindow"
      >
        <jb-icon [name]="searchNavSection.image[0].src"> </jb-icon>
        <hr class="db absolute bottom-0 hr-underline w-0" />
      </a>
    </ng-container>
    <!-- end search section for NOT logged in -->

    <jb-flyout
      #flyoutSignedOut
      [placement]="'bottom-end'"
      [minWidth]="isNewTBSignedOut ? 375 : 296"
      class="jb-shadow-1"
    >
      <div
        #flyoutWrapper
        *ngIf="isNewTBSignedOut"
        class="signed-out-flyout-wrapper scrollable"
      >
        <dot-tb-signed-out
          class="db"
          [content]="newTrueBlueSection.loggedOutSection"
        ></dot-tb-signed-out>
      </div>
      <div *ngIf="!isNewTBSignedOut" class="pa4 signed-out-flyout-wrapper">
        <h3 class="mb3 tl" *ngIf="loggedOutSection.trueBlueText">
          {{ loggedOutSection.trueBlueText }}
        </h3>
        <p
          class="klarheit s-body charcoal mb3 tl"
          *ngIf="loggedOutSection.trueBlueSubText"
        >
          {{ loggedOutSection.trueBlueSubText }}
        </p>
        <!-- top section -->
        <div
          class="flex flex-column tl klarheit"
          *ngIf="loggedOutSection.topSection?.children.length > 0"
        >
          <a
            jbStandaloneLink
            *ngFor="
              let item of loggedOutSection.topSection.children;
              let last = last
            "
            [jbSitemapLinker]="item.href"
            [target]="item.newWindow"
            class="s-body"
            [ngClass]="{ pb2: !last }"
            (click)="flyoutSignedOut.close()"
            [attr.data-qaid]="item.title"
          >
            {{ item.title }}
          </a>
        </div>
        <hr class="bt-0 bl-0 br-0 bb b--medium-gray" *ngIf="showDivider()" />
        <!-- control section -->
        <div class="flex justify-between items-center klarheit">
          <ng-container
            *ngFor="let control of loggedOutSection.ctaSection?.children"
          >
            <ng-container
              *ngIf="control?.actionType === 'BUTTON'; else actionLink"
            >
              <a
                *ngIf="control?.linkType !== 'SIGNIN_LINK'"
                jbButton
                size="medium"
                [jbSitemapLinker]="control.href"
                [target]="control.newWindow"
                [attr.data-qaid]="control.title"
              >
                {{ control.title }}
              </a>
              <a
                *ngIf="control?.linkType === 'SIGNIN_LINK'"
                jbButton
                size="medium"
                href="javascript:void(0)"
                (click)="signIn()"
                [target]="control.newWindow"
                [attr.data-qaid]="control.title"
              >
                {{ control.title }}
              </a>
            </ng-container>
            <ng-template #actionLink>
              <a
                *ngIf="control?.linkType === 'SIGNIN_LINK'"
                jbActionLink
                class="m-auto"
                href="javascript:void(0)"
                (click)="signIn()"
                [target]="control.newWindow"
              >
                <span [attr.data-qaid]="control.title">{{
                  control.title
                }}</span>
                <jb-icon class="pl1" name="rightFilled"></jb-icon>
              </a>
              <a
                *ngIf="control?.linkType !== 'SIGNIN_LINK'"
                jbActionLink
                class="m-auto"
                [jbSitemapLinker]="control.href"
                [target]="control.newWindow"
              >
                <span [attr.data-qaid]="control.title">{{
                  control.title
                }}</span>
                <jb-icon class="pl1" name="rightFilled"></jb-icon>
              </a>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </jb-flyout>
  </div>
  <!-- end not logged in section -->

  <!-- logged in section -->
  <div *ngIf="isLoggedIn" class="fr relative flex items-center">
    <span
      class="white no-underline nowrap menu-item-height"
      (click)="toggleOpen($event, tbLoggedInIndex)"
    >
      <jb-tb-logged-in
        [isMobile]="false"
        [loggedInSection]="loggedInSection"
        [trueBlueLoggedInSection]="newTrueBlueSection.loggedInSection"
        class="dib-ns klarheit"
      >
      </jb-tb-logged-in>
    </span>
    <span class="mh2"></span>

    <!-- cart section for logged in-->
    <ng-container>
      <!-- cart empty -->
      <a
        *ngIf="!!(cartFacade.isCartEmpty | async)"
        class="white pv3 relative-ns w-2"
        [jbSitemapLinker]="shoppingCart.emptyCart[0].link[0].href"
        [attr.target]="shoppingCart.emptyCart[0].link[0].newWindow"
        [attr.aria-label]="shoppingCart.emptyCart[0].alt"
      >
        <jb-image
          [src]="shoppingCart.emptyCart[0].src"
          class="fl pointer fill-white shopping-cart flex justify-center"
        ></jb-image>
      </a>
      <!-- end cart empty -->
      <!-- cart not empty -->
      <a
        *ngIf="!(cartFacade.isCartEmpty | async)"
        class="white pv3 relative-ns w-2"
        [jbSitemapLinker]="shoppingCart.fullCart[0].link[0].href"
        [attr.target]="shoppingCart.fullCart[0].link[0].newWindow"
        [attr.aria-label]="shoppingCart.fullCart[0].alt"
      >
        <jb-image
          [src]="shoppingCart.fullCart[0].src"
          class="fl pointer fill-white shopping-cart flex justify-center"
        ></jb-image>
      </a>
      <!-- end cart not empty -->
    </ng-container>

    <!-- end cart section for logged in -->

    <!-- search section for logged in -->
    <ng-container *ngIf="searchNavSection">
      <span class="mh2"></span>
      <a
        role="button"
        class="search-nav-link white flex justify-center w-2 pv3 relative"
        href="javascript:void(0);"
        [jbSitemapLinker]="searchNavSection.link[0].href"
        [target]="searchNavSection.link[0].newWindow"
      >
        <jb-icon [name]="searchNavSection.image[0].src"> </jb-icon>
        <hr class="db absolute bottom-0 hr-underline w-0" />
      </a>
    </ng-container>
    <!-- end search section for logged in -->
  </div>
  <!-- end logged in section -->
</nav>

import { parseFooter } from './api-transform/cms-footer.api-transform';

export const CMS_FOOTER_FALLBACK = {
  component: [
    {
      name: 'mainSection',
      data: {
        children: [
          {
            name: 'footer',
            data: {
              children: [
                {
                  href: '/ui-assets/durable-homepage/contract_of_carriage.pdf',
                  newWindow: '_self',
                  type: 'external',
                  title: 'Contract of Carriage',
                },
                {
                  href: '/customer-assurance/customer-service-plan/',
                  newWindow: '_self',
                  title: 'Customer Service Plan',
                  type: 'external',
                },
              ],
              heading: 'Safe Policies',
            },
          },
        ],
      },
    },
    {
      name: 'footnoteSection',
      data: {
        utilityNavigation: [
          {
            links: [
              {
                lang: 'ES',
                newWindow: '_self',
                href: '/component-test',
                desc: 'Spanish',
                type: 'internal',
              },
              {
                lang: 'EN',
                newWindow: '_self',
                href: '/',
                desc: 'English',
                type: 'internal',
              },
              {
                lang: 'FR',
                newWindow: '_self',
                href: '/flying-with-us/cuba',
                desc: 'French',
                type: 'internal',
              },
              {
                lang: 'GR',
                newWindow: '_self',
                href: '/',
                type: 'internal',
                desc: 'German',
              },
            ],
            image: [{ alt: 'Language', src: 'vacations' }],
          },
        ],
        bottomFootNote: [
          {
            copyright: '©2023 JetBlue Airways',
            copy: [],
          },
        ],
      },
    },
  ],
};

export const CMS_FOOTER_FALLBACK_PARSED = parseFooter(
  CMS_FOOTER_FALLBACK as any,
);

import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '@core/events';
import {
  HOST,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  WINDOW,
} from '@core/injection-tokens';

import { LinkExternal } from '../../types';
import { getPathIfInSitemap, handleRoute } from '../linker.utils';
import { SITEMAP_DICTIONARY } from '../sitemap-dictionary.token';

/**
 * Apply this directly to an anchor tag in order to provide context of the
 * `SITEMAP_DICTIONARY`, and via this, determine if the link should be an `href`,
 * or whether we can use `routerLink`.
 *
 * For example: An anchor tag that could hold an internal or external link.
 *
 * @example
 * <a class="white no-underline underline-hover"
 *   [jbSitemapLinker]="link.href">
 *   {{ link.title }}
 * </a>
 *
 * In the case above, we want the anchor tag to use `routerLink` if we know the
 * path is in the `SITEMAP_DICTIONARY`, and `href` if it is not.
 */

@Directive({
  selector: 'a[jbSitemapLinker]',
  host: {
    '[attr.rel]': 'rel',
    '[attr.target]': 'target',
  },
})
export class SitemapLinkerDirective implements OnInit {
  readonly SAFE_REL = 'noopener noreferrer';

  isTargetExternal: boolean;
  rel: string = null;
  url: URL;

  @HostBinding('attr.href') href: string;
  routerLink: string;

  @Input('jbSitemapLinker') jbSitemapLinker: string;

  @Input('target') target = '_self';
  @Input('preventDefault') preventDefault = true;

  constructor(
    @Inject(SITEMAP_DICTIONARY) @Optional() private sitemapDictionary,
    @Inject(HOST) private host: string,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
    @Inject(WINDOW) private window,
    private eventsService: EventsService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this.jbSitemapLinker === 'ASAPP') {
      // Opened ASAPP chat widget
      return;
    }

    if (this.jbSitemapLinker && typeof this.jbSitemapLinker === 'string') {
      if (this.jbSitemapLinker.startsWith('/')) {
        this.jbSitemapLinker = `${this.host}${this.jbSitemapLinker}`;
      }
      if (
        this.jbSitemapLinker.startsWith('#') ||
        this.jbSitemapLinker.startsWith('?')
      ) {
        this.jbSitemapLinker = `${this.host}${this.window.location.pathname}${this.jbSitemapLinker}`;
      }
      if (this.isWebComponent) {
        // Set tabindex for web component context
        this.renderer.setAttribute(this.el.nativeElement, 'tabindex', '0');
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
      }
      try {
        this.url = new URL(this.jbSitemapLinker);
        this.isTargetExternal =
          this.target === '_blank' || this.target === '_document';
        this.rel = this.getRel(this.isTargetExternal);

        if (this.url && !this.isWebComponent) {
          const { href, routerLink } = this.getHrefRouterLink();
          this.href = routerLink ? `${routerLink}${this.url.search}` : href;
          this.routerLink = routerLink;
        }
      } catch (e) {
        console.error(`Invalid link: "${this.jbSitemapLinker}":`);
        console.error(e);
      }
    } else {
      console.error('No link provided!');
    }
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (
      this.routerLink ||
      this.isWebComponent === 'header' ||
      this.isWebComponent === 'footer'
    ) {
      if (!!this.preventDefault) {
        event.preventDefault();
      }
      if (event.ctrlKey || event.metaKey) {
        this.window.open(this.href, '_blank', this.SAFE_REL);
        return;
      }
      this.redirect();
    }
  }

  @HostListener('keydown.enter', ['$event'])
  onKeyDown(event) {
    if (
      this.routerLink ||
      this.isWebComponent === 'header' ||
      this.isWebComponent === 'footer'
    ) {
      event.preventDefault();
      this.redirect();
    }
  }

  redirect(): void {
    const { href, routerLink } = this.getHrefRouterLink();
    handleRoute(
      this.isWebComponent,
      this.eventsService,
      href,
      routerLink,
      this.url,
      this.router,
    );
  }

  private getRel(isTargetExternal) {
    if (!isTargetExternal) {
      return null;
    }
    return this.SAFE_REL;
  }

  private getHrefRouterLink(): LinkExternal {
    let href;
    let routerLink;

    if (!this.sitemapDictionary || this.isTargetExternal) {
      href = this.url.href;
    } else {
      const path = getPathIfInSitemap(
        this.url,
        this.sitemapDictionary,
        this.host,
      );
      if (path) {
        routerLink = path;
      } else {
        href = this.url.href;
      }
    }
    return { href, routerLink };
  }
}

import { UrlTree } from '@angular/router';

/**
 * Returns the new language path based on the provided URL tree and language settings.
 *
 * @param urlTree - The URL tree representing the current URL.
 * @param supportedLanguages - An array of supported language codes.
 * @param newLang - The new language code to be used.
 * @param filterOutEN - Optional. Specifies whether to filter out the 'en' language code. Default is false.
 * @returns An array of string segments representing the new language path.
 */
export const getNewLanguagePath = (
  urlTree: UrlTree,
  supportedLanguages: string[],
  newLang: string,
  filterOutEN = false,
): string[] => {
  const urlSegments = (urlTree.root.children['primary']?.segments || []).map(
    seg => seg.toString(),
  );

  const urlSegmentsContainLanguageCode = supportedLanguages.includes(
    urlSegments[0],
  );

  // Construct the new segments array
  const newSegments = urlSegments.length
    ? [newLang, ...urlSegments.slice(urlSegmentsContainLanguageCode ? 1 : 0)]
    : [newLang];

  const finalSegments = filterOutEN
    ? newSegments.filter(seg => seg !== 'en')
    : newSegments;

  return finalSegments.length ? finalSegments : ['/'];
};

/**
 * Detects the language based on the provided parameters.
 * If `languageInUrlEnabled` is true, the source of truth is the URL.
 * Otherwise, it falls back to the cookie language or the browser language.
 *
 * @param languageInUrlEnabled - A boolean indicating whether language is enabled in the URL.
 * @param pathname - The current URL pathname.
 * @param supportedLanguages - An array of supported languages.
 * @param windowObj - The window object.
 * @param cookieLanguage - The language stored in the cookie.
 * @param defaultLangue - The default language to fall back to if no language is detected. Defaults to 'en'.
 * @returns The detected language.
 */
// we invoke this function when we init LanguageService or when router Navigates to new route
// to-do it should return only supported languages
export const detectLanguage = (
  languageInUrlEnabled: boolean,
  pathname: string,
  supportedLanguages: string[],
  windowObj: Window,
  cookieLanguage: string,
  defaultLanguage: string = 'en',
): string => {
  // JetBlue set Cookie or Browser Cookie
  const getNonDefaultLanguage = (
    languages: string[],
    defaultLang: string,
  ): string | null => {
    for (const lang of languages) {
      if (lang && lang !== defaultLang) {
        return lang;
      }
    }
    return null;
  };

  // to-do remove these we only care about cookie if
  // languageInUrlEnabled is false
  const cookieDetectedLanguage = getNonDefaultLanguage(
    [
      cookieLanguage,
      windowObj.document.getElementsByTagName('html')[0].lang,
      windowObj.navigator.language.toLowerCase().substring(0, 2),
    ],
    defaultLanguage,
  );

  // if this is true source of truth is URL not cookie
  if (languageInUrlEnabled) {
    // Get the first segment of the URL path
    // firstUrl segment is not necessarily language, example
    // /fr/flight-trakcer - in this case firstSegment is language french
    // /flight-tracker - in this case firstsegment is the page
    // to-do use regex to get url language also the router is parsed by the router using the param :lang
    // however can catch that only after router start navigation
    const firstUrlSegment = pathname.split('/')[1];
    // Detect language from the URL segment if it's supported, otherwise use the default language
    const urlDetectedLanguage = supportedLanguages.includes(firstUrlSegment)
      ? firstUrlSegment
      : defaultLanguage;

    // If URL language is different from cookie language and is the default language, use cookie language
    // and ie Url is showing en but cookie/browser is showing another language
    // we should not use cookie here the source of truth is URL so we should get language from url
    // if no language in url or the language is not supported fallback to default language en
    // to-do remove cookie from condition
    if (
      urlDetectedLanguage !== cookieDetectedLanguage &&
      urlDetectedLanguage === defaultLanguage
    ) {
      // to-do check and if required remove
      // if languageInUrlEnabled is true then according to top comment source of true is url
      // not cookie
      // also if lang is in cookie, it was already validated by languageService setLanguage
      // remove include
      return supportedLanguages.includes(cookieDetectedLanguage)
        ? cookieDetectedLanguage
        : defaultLanguage;
    }

    // Return the language detected from the URL
    // if source of truth is URL we need to validate language againt supportedLanguage before returning it
    return urlDetectedLanguage;
  }

  // Return the language detected from the cookie, HTML, or browser, ensuring it's supported
  // Here source of truth is cookie
  return supportedLanguages.includes(cookieDetectedLanguage)
    ? cookieDetectedLanguage
    : defaultLanguage;
};

<dot-okta-header *ngIf="!(isNativeApp$ | async)"></dot-okta-header>
<div id="okta-widget-container" class="okta-container ph5-ns ph3 center-ns">
  <!--
      .okta-widget-with-footer changes bottom padding and removes
      bottom border radius of the widget container because a footer
      is rendered as the "bottom end" of the widget instead
-->
  <div
    class="br2-ns bg-white center pa6-ns"
    [ngClass]="isOktaWidget ? 'okta-widget-with-footer' : ''"
  >
    <div
      class="center-ns tl bg-white"
      [ngClass]="{
        'pv5-ns': isOktaWidget,
        pv5: !isOktaWidget,
        'pb0 pt5': isTacAccept
      }"
    >
      <jb-icon
        class="db mb4"
        [iconSourceUrl]="logo.src"
        [label]="logo.alt || 'logo'"
        *ngIf="logo && logo.src"
      >
      </jb-icon>
      <ng-content></ng-content>
    </div>
    <ng-content select="dot-forgot-password-trace-section"></ng-content>
  </div>
</div>
<!--
      #okta-widget-footer-container renders only when 'dot-okta-widget.component'
      is the content of the dot-okta-container and is not available in the other
      non-OKTA sign in/sign up/password flows
-->
<div
  *ngIf="isOktaWidget && !isOktaWidget.loading && !isTacAccept"
  id="okta-widget-footer-container"
  class="ph5-ns center-ns"
>
  <div id="okta-widget-footer">
    <span *ngFor="let link of trueBlueResponse?.pageLink">
      <a href="{{ link.href }}" target="{{ link.newWindow }}">{{
        link.title
      }}</a>
    </span>
  </div>
</div>

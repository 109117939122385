import { AuthProfile } from '@store/auth/types';

import {
  BookerMetrics,
  ContentMetrics,
  HeaderMetrics,
} from '../mocks/page-metrics.type';

export const sendBookerLoadFSEvent = (
  window: Window,
  payload: BookerMetrics,
) => {
  if (window['FS']) {
    window['FS'].event('Page Metrics: Booker Load', payload);
  }
};

export const sendHeaderLoadFSEvent = (
  window: Window,
  payload: HeaderMetrics,
) => {
  if (window['FS']) {
    window['FS'].event('Page Metrics: Header Load', payload);
  }
};

export const sendContentLoadFSEvent = (
  window: Window,
  payload: ContentMetrics,
) => {
  if (window['FS']) {
    window['FS'].event('Page Metrics: Content Load', payload);
  }
};

export const sendUpdatePaymentsFailureFSEvent = (
  window: Window,
  errorMessage: string,
) => {
  if (window['FS']) {
    window['FS'].event('UPDATE Payments Failure', {
      reason: errorMessage,
    });
  }
};

export const sendDeletePaymentsFailureFSEvent = (
  window: Window,
  errorMessage: string,
) => {
  if (window['FS']) {
    window['FS'].event('DELETE Payments Failure', {
      reason: errorMessage,
    });
  }
};

export const fullstoryIdentify = (
  profile: AuthProfile,
  travelBankBalance: number,
  window: Window,
) => {
  if (!window['FS']) {
    return;
  }

  const isGuest = !profile && !travelBankBalance;

  let userAge;
  if (profile && profile.birthDate) {
    const splitDateString = profile.birthDate.split('-');
    const ageDate = new Date(
      Number(splitDateString[0]),
      Number(splitDateString[1]) - 1,
      Number(splitDateString[2]),
    );
    const diffMs = Date.now() - ageDate.getTime();
    const ageDiffDate = new Date(diffMs);
    userAge = Math.abs(ageDiffDate.getUTCFullYear() - 1970);
  }

  let trueBlueStatus;
  if (isGuest) {
    trueBlueStatus = 'Guest';
  } else if (profile.isMosaic) {
    trueBlueStatus = profile.mosaicTier;
  } else {
    trueBlueStatus = 'TrueBlue';
  }

  let pointsBalance;
  if (isGuest) {
    pointsBalance = null;
  } else {
    pointsBalance = profile.points ? Number(profile.points) : null;
  }

  if (!isGuest) {
    window['FS'].identify(profile.membershipid, {
      displayName: `${profile.fname} ${profile.lname}`,
      TrueBlueStatus: trueBlueStatus,
      age: userAge,
      pointsBalance,
      travelBankBalance,
      cardType: isGuest ? null : profile.cardStatus && profile.cardStatus[0],
    });
  }
};

export const PAGES_WITH_THEIR_OWN_CONTENT_DONE_LOADING_CALL = ['route-map'];

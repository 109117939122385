import { Injectable } from '@angular/core';
import { CmsTemplateRouteData } from '@core/cms';
import {
  FooterResponse,
  FooterResponseRaw,
  LanguageLink,
} from '@core/cms/types/cms-footer.response.type';
import { HeaderResponse } from '@core/cms/types/cms-header.response.type';
import {
  HeaderInnerType,
  TravelAlerts,
} from '@shared/header/types/header.inner.type';
import { Image } from '@shared/types/image.type';
import { Observable } from 'rxjs';

import { StoreService } from '../shared/store.service';
import {
  ChooseGlobalComponentBackgroundUrl,
  FetchGlobalComponentBackgroundUrl,
  LoadFooter,
  LoadFooterRaw,
  LoadFooterTranslation,
  LoadHeader,
  LoadHeaderRaw,
  LoadHeaderTranslation,
  LoadTemplate,
  RequestFooter,
  RequestHeader,
  RequestTemplate,
  SetGlobalComponentConfig,
  SetHeaderTravelAlertsContent,
  SetLastRequestedEndpoint,
  SetPrimaryRenderingStatus,
  SetRenderedHeader,
  SetSecondaryRenderingStatus,
  SetTertiaryRenderingStatus,
} from './global.actions';
import * as GlobalConfigSelectors from './global.selectors';
import { GlobalComponentState, GlobalState, PageData } from './types';

@Injectable({ providedIn: 'root' })
export class GlobalFacade {
  global: Observable<GlobalState> = this.storeService.getState(
    GlobalConfigSelectors.global,
  );
  globalComponent: Observable<GlobalComponentState> =
    this.storeService.getState(GlobalConfigSelectors.globalComponent);
  travelAlerts: Observable<TravelAlerts> = this.storeService.getState(
    GlobalConfigSelectors.travelAlerts,
  );
  header = this.storeService.getState(GlobalConfigSelectors.header);
  translatedHeader$ = this.storeService.getState(
    GlobalConfigSelectors.translatedHeader,
  );
  isCountdownClock = this.storeService.getState(
    GlobalConfigSelectors.isCountdownClock,
  );
  footer: Observable<FooterResponse> = this.storeService.getState(
    GlobalConfigSelectors.footer,
  );
  translatedFooter$ = this.storeService.getState(
    GlobalConfigSelectors.translatedFooter,
  );
  footerLanguageLinks: Observable<LanguageLink[]> = this.storeService.getState(
    GlobalConfigSelectors.footerLanguageLinks,
  );
  template: Observable<any> = this.storeService.getState(
    GlobalConfigSelectors.template,
  );
  lastRequestedEndpoint: Observable<string> = this.storeService.getState(
    GlobalConfigSelectors.lastRequestedEndpoint,
  );
  templateRendered: Observable<boolean> = this.storeService.getState(
    GlobalConfigSelectors.templateRendered,
  );
  isHeaderRendered: Observable<boolean> = this.storeService.getState(
    GlobalConfigSelectors.isHeaderRendered,
  );
  pageData: Observable<PageData> = this.storeService.getState(
    GlobalConfigSelectors.pageData,
  );

  isCmsFailed: Observable<boolean> = this.storeService.getState(
    GlobalConfigSelectors.isCmsFailed,
  );

  constructor(private storeService: StoreService) {}

  setGlobalComponentConfig(config: GlobalComponentState) {
    this.storeService.dispatchAction(new SetGlobalComponentConfig(config));
  }

  fetchGlobalComponentBackgroundUrl() {
    this.storeService.dispatchAction(new FetchGlobalComponentBackgroundUrl());
  }

  chooseGlobalComponentBackgroundUrl(images: Image[]) {
    this.storeService.dispatchAction(
      new ChooseGlobalComponentBackgroundUrl(images),
    );
  }

  setRenderedHeader(isHeaderRendered: boolean) {
    this.storeService.dispatchAction(new SetRenderedHeader(isHeaderRendered));
  }

  setTranslatedHeaderContent(translatedHeaderContent: Partial<HeaderResponse>) {
    this.storeService.dispatchAction(
      new LoadHeaderTranslation(translatedHeaderContent),
    );
  }

  setTranslatedFooterContent(
    translatedFooterContent: Partial<FooterResponseRaw>,
  ) {
    this.storeService.dispatchAction(
      new LoadFooterTranslation(translatedFooterContent),
    );
  }

  setHeaderTravelAlertsContent(config) {
    this.storeService.dispatchAction(new SetHeaderTravelAlertsContent(config));
  }

  requestHeader(
    personalizationData: CmsTemplateRouteData = null,
  ): RequestHeader {
    return new RequestHeader(personalizationData);
  }

  loadHeader(response: HeaderInnerType): LoadHeader {
    return new LoadHeader(response);
  }

  loadHeaderRaw(response: HeaderResponse): LoadHeaderRaw {
    return new LoadHeaderRaw(response);
  }

  requestFooter(): RequestFooter {
    return new RequestFooter();
  }

  loadFooter(response: FooterResponse): LoadFooter {
    return new LoadFooter(response);
  }

  loadFooterRaw(response: FooterResponseRaw): LoadFooterRaw {
    return new LoadFooterRaw(response);
  }

  requestTemplate(payload): RequestTemplate {
    return new RequestTemplate(payload);
  }

  loadTemplate(response): LoadTemplate {
    return new LoadTemplate(response);
  }

  setLastRequestedEndpoint(endpoint: string): SetLastRequestedEndpoint {
    return new SetLastRequestedEndpoint(endpoint);
  }

  setPrimaryRenderingStatus(status: boolean) {
    this.storeService.dispatchAction(new SetPrimaryRenderingStatus(status));
  }

  setSecondaryRenderingStatus(status: boolean) {
    this.storeService.dispatchAction(new SetSecondaryRenderingStatus(status));
  }

  setTertiaryRenderingStatus(status: boolean) {
    this.storeService.dispatchAction(new SetTertiaryRenderingStatus(status));
  }
}

<button
  (click)="toggleLoggedInModal()"
  class="pa0 logged-in-button flex items-center white no-underline profile-container relative"
>
  <span class="flex justify-end items-center mr0-ns nt2">
    <jb-icon
      *ngIf="profile.isMosaic === 'true'"
      name="avatarMosaicStatus"
      class="nr2 z-2 mosaic-logo mt3"
      width="22"
      label="mosaic"
      data-qaid="mosaicStatus"
    >
    </jb-icon>
    <span class="flex justify-center z-1 white f5 mr0-m br-100 mt2 mt0-ns">
      <jb-avatar
        *ngIf="!(profilePicture$ | async) && !profile.profileImage"
        initials="{{ firstNameInitial }}{{ lastNameInitial }}"
        size="small"
      >
      </jb-avatar>
      <jb-avatar
        size="small"
        *ngIf="(profilePicture$ | async) || profile.profileImage as pic"
        [src]="pic"
      >
      </jb-avatar>
    </span>
    <hr
      [ngClass]="(isModalOpen$ | async) ? 'w-100' : 'w-0'"
      class="db absolute bottom-0 hr-underline"
    />
  </span>
</button>
<div
  *ngIf="(isModalOpen$ | async) && isNewTBSignedIn"
  class="logged-in-modal bg-white"
>
  <dot-tb-signed-in
    [trueBlueLoggedInSection]="trueBlueLoggedInSection"
  ></dot-tb-signed-in>
</div>
<div
  *ngIf="(isModalOpen$ | async) && !isNewTBSignedIn"
  class="logged-in-modal bg-white"
>
  <div class="bg-off-white ph4 pb4 pt3 pv3-ns charcoal">
    <div class="db-ns flex items-end justify-between">
      <!-- User first name and last name -->
      <div class="user-name mb2 f4 klarheit" data-qaid="firstNameLastInitial">
        <span>{{ loggedInSection.TrueBlueGreetings }}</span
        >{{ firstName }} {{ lastNameInitial }}.
      </div>
      <jb-icon
        *ngIf="profile.isMosaic === 'true'"
        width="76"
        name="mosaicLockupColor"
        class="db dropdown-mosaic h-100 lh-solid mt2-l mb1-l mv1-m"
        label="mosaic"
      >
      </jb-icon>
    </div>
    <div *ngIf="!!points" class="lh-copy pt1 pv0-ns mb1-ns s-body">
      <span class="b klarheit" data-qaid="points">{{ points }}</span>
      <span class="b klarheit" data-qaid="loggedInPoints">
        {{ loggedInSection.points }}</span
      >
    </div>
    <!-- Travel bank credit -->
    <div
      *ngIf="(travelBankBalance$ | dotFilterUndefined | async) as tbBalance"
      class="tb-number lh-copy s-body mv1-m mb1-l mt0-l mt1 mb2"
    >
      <span class="b klarheit" data-qaid="travelBankBalance"
        >{{ tbBalance | currency }} Travel Bank credit</span
      >
    </div>
    <!-- Trueblue points -->
    <div class="tb-number lh-copy s-body mv1-m mb1-l mt0-l mt1 mb2 klarheit">
      {{ loggedInSection.TrueBlueNrtxt
      }}<span data-qaid="trueBlueNumber">{{ tbNumber }}</span>
    </div>
  </div>
  <!-- Start of top section -->
  <ul class="list pv4 ph4 ma0">
    <li
      class="l-body copy-s-ns mb2 mt0"
      *ngFor="let link of loggedInSection.topSection.children"
    >
      <a
        [target]="link.newWindow"
        [dotLinkIcon]="link.newWindow === '_self' ? null : link.newWindow"
        rel="noopener"
        [jbSitemapLinker]="link.href"
        class="royal-blue no-underline underline-hover klarheit"
        >{{ link.title }}</a
      >
    </li>
    <hr class="mv3 bl-0 br-0 bt-0 bb b--medium-gray" />
    <!-- Start of bottom children -->
    <ng-container
      *ngFor="
        let link of loggedInSection?.bottomSection?.children;
        let i = index
      "
    >
      <li *ngIf="i !== 1" class="l-body copy-s-ns mv2 mb0-ns">
        <a
          [target]="link.newWindow"
          [dotLinkIcon]="link.newWindow === '_self' ? null : link.newWindow"
          rel="noopener"
          [jbSitemapLinker]="link.href"
          class="royal-blue no-underline underline-hover klarheit"
          >{{ link.title }}</a
        >
      </li>
      <li *ngIf="i === 1 && enrollChild" class="l-body copy-s-ns mv2">
        <a
          [target]="link.newWindow"
          [dotLinkIcon]="link.newWindow === '_self' ? null : link.newWindow"
          rel="noopener"
          [jbSitemapLinker]="link.href"
          class="royal-blue no-underline underline-hover klarheit"
          >{{ link.title }}</a
        >
      </li>
    </ng-container>
    <!-- End of bottom children -->
    <hr class="mv3 bl-0 br-0 bt-0 bb b--medium-gray" />
    <li class="l-body copy-s-ns mv2 mb0-ns">
      <a
        href="javascript:void(0)"
        (click)="handleSignOut()"
        data-qaid="signOut"
        class="royal-blue no-underline underline-hover klarheit"
        >{{ loggedInSection.signoutSection.children[0].title }}</a
      >
    </li>
  </ul>
</div>

/**
 * Function that register Logs into Full Story
 * @param {string} eventName The name of the event that will be registered on FS.
 * @param {{}} eventProperties Properties set on FS for each Event.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function registerLogFullStory(
  eventName: string,
  eventProperties = {},
  fsWindow: Window,
) {
  if (typeof window === 'undefined') {
    // req for ssr
    return;
  }

  if (fsWindow['FS']) {
    fsWindow['FS'].event(eventName, eventProperties);
  }
}

/**
 * FullStory is an asynchronous script and for some cases, we must wait it to load before trying to register a log.
 * Use this function when you need to register a log BEFORE any user interaction.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function waitForFullStory(fsWindow: Window) {
  if (typeof window === 'undefined') {
    // req for ssr
    return Promise.resolve(null);
  }
  return new Promise<void>((resolve, reject) => {
    let attempts = 0;
    const fsInterval = setInterval(() => {
      if (fsWindow['FS']) {
        clearInterval(fsInterval);
        resolve();
      } else {
        attempts++;
      }
      if (attempts > 50) {
        clearInterval(fsInterval);
        reject();
      }
    }, 250);
  });
}

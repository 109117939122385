import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AppConfigService } from '@core/app-config';
import { LanguageService } from '@core/language/language.service';

@Injectable({ providedIn: 'root' })
export class LanguageGuard implements CanActivate {
  // to-do simplify this condition
  supportedLanguages =
    this.appConfig.supportedLanguages &&
    Object.keys(this.appConfig.supportedLanguages).length
      ? Object.keys(this.appConfig.supportedLanguages)
      : ['en', 'fr', 'de', 'es'];
  hasNavigated = false;

  constructor(
    private router: Router,
    private appConfig: AppConfigService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  // this guard is injected in router config in generateRoutes()
  // when we select a lang different than english
  // this runs only after app-routing is initialized and only if the url has a language
  // example /es/flight-tracker or /fr/flight-tracker even if path is only /es or /fr
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    const lang = route.params['lang'];
    if (this.supportedLanguages.includes(lang)) {
      const currentInternalLang = this.languageService.getLanguage();
      // this is set in language service
      // the lines below are not required, because in LanguageService
      // we call setLanguage on router navigation to update the HTML element lang att
      // and the privateLanguage$ observer
      // if we select a language from the footer we call "switchLanguage" and
      // this calls setLanguage too.
      this.document.documentElement.setAttribute('lang', lang);
      if (currentInternalLang !== lang) {
        this.languageService.privateLanguage$.next(lang);
      }
      return true;
    } else {
      // if the lang in route is not supported this block will recover current
      // selected language and prepending it to route then redirect to the new route
      // question: if the lang is not supported should we quit fast instead ?
      const urlSegments = state.url.split('/');

      const queryParams = state.root.queryParams;
      return this.defaultLanguageForInternalRouting(
        urlSegments.filter(Boolean),
        lang,
        queryParams,
      );
    }
  }

  // this is prepending the selected language to the activated route
  private defaultLanguageForInternalRouting(
    urlSegments: string[],
    langParameter: string,
    queryParams: Params,
  ): UrlTree {
    // selected language
    const setLang = this.languageService.getLanguage() || 'en';
    // in the case of someone putting in a url without a langauge parameter, 'langParameter' will be
    // the first segment of the url that we want to keep, so a basic check to see if langParameter is
    // a string and a string that is not exactly 2 characters long will tell us if we need to keep it
    // if it is not 2 characters long, we will assume it is a fragment of the url that we want to keep

    const langParamIsUrlSegment =
      // greater than is fine for now, ideally we have a full language map or something to be explicit
      typeof langParameter === 'string' && langParameter.length > 2;

    // prepend language to route
    // condition is always false
    const newSegments = this.supportedLanguages.includes(langParameter)
      ? [langParameter, ...urlSegments]
      : [setLang].concat(
          langParamIsUrlSegment ? [langParameter] : [],
          urlSegments.slice(1),
        );

    const urlTree = this.router.parseUrl(newSegments.join('/'));
    urlTree.queryParams = queryParams;
    return urlTree;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  Inject,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DisableBodyScrollService, WINDOW } from '@core/index';
import { JBLogoComponent } from '@shared/jb-logo';
import { AuthFacade } from '@store/auth/auth.facade';
import { RouterFacade } from '@store/router/router.facade';
import { shareReplay, skipWhile } from 'rxjs/operators';

import { HeaderService } from '../header.service';
import {
  HeaderInnerType,
  JetBlueLogoSection,
  LoggedInSection,
  SearchNavSection,
  ShoppingCartSection,
  SubNavSections,
  TrueBlueNewSection,
  TrueBlueSection,
} from '../types/header.inner.type';

/**
 * @description Header displayed in a mobile viewport
 * @WhereIsItUsed header component
 */
@Component({
  selector: 'jb-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileComponent implements OnInit {
  private _destroy = inject(DestroyRef);

  @Input() set headerData(content: HeaderInnerType) {
    this.newTrueBlueSection = content.trueBlue;
    this.searchNavSection = content.search;
    this.shoppingCart = content.shoppingCart;
    this.trueBlueSection = content.login;
    this.loggedInSection = content.login.loggedInSection;
    this.subNavSections = content.subNavSections;
    this.jetblueLogo = content.jetblueLogo;
  }
  @ViewChild('headerLogo') headerLogo: JBLogoComponent;

  // host binding to dynamically add z-index: 9999 to the header host element
  @HostBinding('class.z9999') headerOnTop = false;
  isLoggedIn$ = this.authFacade.isProfileLoaded.pipe(shareReplay());
  isModalOpen$ = this.headerService.isModalOpen$;
  isNavMenuOpen$ = this.headerService.isNavMenuOpen$;
  newTrueBlueSection: TrueBlueNewSection;
  searchNavSection: SearchNavSection;
  shoppingCart: ShoppingCartSection;
  trueBlueSection: TrueBlueSection;
  loggedInSection: LoggedInSection;
  jetblueLogo: JetBlueLogoSection;
  subNavSections: SubNavSections;

  @Input() hideTb;

  constructor(
    @Inject(WINDOW) public window,
    private disableBodyScrollService: DisableBodyScrollService,
    private authFacade: AuthFacade,
    private routerFacade: RouterFacade,
    private elementRef: ElementRef,
    private headerService: HeaderService,
  ) {}

  ngOnInit() {
    this.routerFacade.currentRoute
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(() => {
        this.headerService.closeAllModals();
      });

    this.isModalOpen$
      .pipe(
        takeUntilDestroyed(this._destroy),
        skipWhile(val => !val),
      )
      .subscribe(isOpen => {
        if (isOpen) {
          this.disableBodyScrollService.disableScroll();
          this.headerOnTop = true;
        } else {
          this.disableBodyScrollService.enableScroll();
          this.scrollToTop();
          this.headerOnTop = false;
        }
      });
  }

  toggleNavMenu() {
    this.headerService.toggleNavMenu();
  }

  scrollToTop() {
    if (this.window.scrollY < this.elementRef.nativeElement.offsetTop) {
      (this.elementRef.nativeElement as HTMLElement).scrollIntoView();
      // Scroll down 2 pixels so global nav is out of view by Intersection Observer
      this.window.scrollBy(0, 2);
    }
  }
}
